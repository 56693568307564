import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getById, getData } from "../Utils/db";
import Card from "./SemiComponents/Card";
import { findRelatedProducts } from "../Utils/RelatedProducts";
import CheckoutForm from "./SemiComponents/CheckoutForm";
import Footer from "../Components/SemiComponents/Footer";
import Navbar from "./SemiComponents/Navbar";
import "./Styles/SelectedProduct.css";
import { marked } from "marked";
import DOMPurify from "dompurify";
import Loader from "./SemiComponents/Loader";
import { Helmet } from "react-helmet";

const formatDescription = (description) => {
  if (!description) return { __html: "" };
  return { __html: DOMPurify.sanitize(marked(description)) };
};

function SelectedProduct() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get("id");

  const { selectedProduct: stateProduct } = location.state || {};

  const [selectedProduct, setSelectedProduct] = useState(stateProduct || null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchProductData = async () => {
      try {
        if (!uuid) throw new Error("Product ID is missing in the URL.");

        setLoading(true);

        const allProducts = await getData("products");
        const product = stateProduct || (await getById("products", uuid));

        if (!product) throw new Error("Product not found.");

        setSelectedProduct(product);

        const related = findRelatedProducts(product, allProducts);
        setRelatedProducts(related || []);
      } catch (err) {
        console.error(err.message);
        setError(err.message);
        navigate("/not-found");
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [uuid, navigate, stateProduct]);

  const formatPrice = (price) => `ZK ${price.toLocaleString()}`;

  const getHrefFromSrc = (src) => {
    const matches = src?.match(/https:\/\/i\.ibb\.co\/([a-zA-Z0-9]+)\/.*/);
    return matches ? `https://ibb.co/${matches[1]}` : "#";
  };

  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  if (loading) return <Loader />;
  if (error) {
    return (
      <div className="error-container">
        <Navbar />
        <h1>Error</h1>
        <p>{error}</p>
        <Footer />
      </div>
    );
  }

  return (
    <div className="selected-product-container">
      <Helmet>
        <title>{selectedProduct?.title || "Product"} | Multistore Hub</title>
        <meta name="description" content={selectedProduct?.description || "Product details page for Multistore Hub."} />
      </Helmet>
      
      <Navbar />
      <div className="product-page">
        <div className="product-container">
          <div className="product-image">
            <a href={getHrefFromSrc(selectedProduct?.imageURL)}>
              <img src={selectedProduct?.imageURL} alt={selectedProduct?.title} />
            </a>
            <p className="product-price">{formatPrice(selectedProduct?.price)}</p>
          </div>

          <div className="product-form-container">
            <h1>{selectedProduct?.title}</h1>
            <CheckoutForm item={selectedProduct} />
          </div>
        </div>

        <div className="product-container">
            <div className="product-info">
              <h1>Product Details</h1>
              <div className="product-description">
                  <p dangerouslySetInnerHTML={formatDescription(
                    showFullDescription ? selectedProduct?.description : `${selectedProduct?.description?.slice(0, 200)}...`
                  )} />
                  {selectedProduct?.description?.length > 200 && (
                    <button onClick={toggleDescription} className="btn read-more-btn">
                      {showFullDescription ? "Read Less" : "Read More"}
                    </button>
                  )}
              </div>
            </div>
          </div>

        <div className="product-container">
          <div>
            <h2>Related Products</h2>
            <div className="products-list">
              {relatedProducts.length > 0 ? (
                relatedProducts.slice(1, 6).map((product) => (
                  <Card
                    key={product.uuid}
                    title={product.title}
                    description={product.description}
                    image={product.imageURL}
                    price={product.price}
                    onClick={() => navigate(`/products/${product.title}?id=${product.uuid}`, {
                      state: { selectedProduct: product }
                    })}
                  />
                ))
              ) : (
                <p>No related products found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SelectedProduct;
